export const logs = [

  {
      title: "offer.janowo.diary.timeline.february",
      year: 2025,
      pics: [
          [
            "/assets/images/ojp3/diary/2025-02/20250226_082610.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_082612.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_082614.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_082616.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_082618.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_082620.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_082638.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_082850.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_082920.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_082922.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_082924.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_082926.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_084844.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_084846.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_084848.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_084852.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_084854.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_084856.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_084900.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_084911.jpg",
            "/assets/images/ojp3/diary/2025-02/20250226_084915.jpg",

          ],
          [
            "/assets/images/ojp3/diary/2025-02/mini_20250226_082610.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_082612.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_082614.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_082616.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_082618.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_082620.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_082638.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_082850.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_082920.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_082922.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_082924.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_082926.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_084844.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_084846.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_084848.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_084852.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_084854.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_084856.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_084900.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_084911.jpg",
            "/assets/images/ojp3/diary/2025-02/mini_20250226_084915.jpg",

          ],
      ],
      alts: [],
  }, {
    title: "offer.janowo.diary.timeline.october",
    year: 2024,
    pics: [
      [
        "/assets/images/ojp3/diary/2024-10/20241025_160518.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_160600.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_160646.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_160732.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161251.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161322.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161347.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161426.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161456.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161502.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161531.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161539.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161545.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161549.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161613.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161630.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161643.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161646.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161655.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161753.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161758.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161923.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161937.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_161957.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_162010.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_162014.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_162102.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_162114.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_162124.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_162210.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_162244.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_162627.jpg",
        "/assets/images/ojp3/diary/2024-10/20241025_163020.jpg",
      ],
      [
        "/assets/images/ojp3/diary/2024-10/mini_20241025_160518.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_160600.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_160646.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_160732.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161251.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161322.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161347.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161426.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161456.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161502.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161531.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161539.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161545.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161549.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161613.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161630.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161643.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161646.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161655.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161753.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161758.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161923.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161937.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_161957.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_162010.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_162014.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_162102.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_162114.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_162124.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_162210.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_162244.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_162627.jpg",
        "/assets/images/ojp3/diary/2024-10/mini_20241025_163020.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.september",
    year: 2024,
    pics: [
      [
        "/assets/images/ojp3/diary/2024-09/20240925_165556.jpg",
        "/assets/images/ojp3/diary/2024-09/20240925_165624.jpg",
        "/assets/images/ojp3/diary/2024-09/20240925_165627.jpg",
        "/assets/images/ojp3/diary/2024-09/20240925_165733.jpg",
        "/assets/images/ojp3/diary/2024-09/20240925_165816.jpg",
        "/assets/images/ojp3/diary/2024-09/20240925_165840.jpg",
        "/assets/images/ojp3/diary/2024-09/20240925_165959.jpg",
        "/assets/images/ojp3/diary/2024-09/20240925_170101.jpg",
        "/assets/images/ojp3/diary/2024-09/20240925_170123.jpg",
      ],
      [
        "/assets/images/ojp3/diary/2024-09/mini_20240925_165556.jpg",
        "/assets/images/ojp3/diary/2024-09/mini_20240925_165624.jpg",
        "/assets/images/ojp3/diary/2024-09/mini_20240925_165627.jpg",
        "/assets/images/ojp3/diary/2024-09/mini_20240925_165733.jpg",
        "/assets/images/ojp3/diary/2024-09/mini_20240925_165816.jpg",
        "/assets/images/ojp3/diary/2024-09/mini_20240925_165840.jpg",
        "/assets/images/ojp3/diary/2024-09/mini_20240925_165959.jpg",
        "/assets/images/ojp3/diary/2024-09/mini_20240925_170101.jpg",
        "/assets/images/ojp3/diary/2024-09/mini_20240925_170123.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.july",
    year: 2024,
    pics: [
      [
        "/assets/images/ojp3/diary/2024-07/20240701_105127.jpg",
        // "/assets/images/ojp3/diary/2024-07/20240701_113641.jpg",
        // "/assets/images/ojp3/diary/2024-07/20240701_113644.jpg",
        "/assets/images/ojp3/diary/2024-07/20240703_102652.jpg",
        "/assets/images/ojp3/diary/2024-07/20240704_104127.jpg",
        "/assets/images/ojp3/diary/2024-07/20240704_104145.jpg",
        "/assets/images/ojp3/diary/2024-07/20240717_133129.jpg",
        "/assets/images/ojp3/diary/2024-07/20240719_083149.jpg",
        "/assets/images/ojp3/diary/2024-07/20240719_085236.jpg",
        "/assets/images/ojp3/diary/2024-07/20240720_120552.jpg",
        "/assets/images/ojp3/diary/2024-07/20240720_120554.jpg",
        "/assets/images/ojp3/diary/2024-07/IMG_20240722_122033_608.jpg",
        "/assets/images/ojp3/diary/2024-07/IMG_20240729_071435_968.jpg",
      ],
      [
        "/assets/images/ojp3/diary/2024-07/mini_20240701_105127.jpg",
        // "/assets/images/ojp3/diary/2024-07/mini_20240701_113641.jpg",
        // "/assets/images/ojp3/diary/2024-07/mini_20240701_113644.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_20240703_102652.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_20240704_104127.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_20240704_104145.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_20240717_133129.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_20240719_083149.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_20240719_085236.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_20240720_120552.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_20240720_120554.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_IMG_20240722_122033_608.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_IMG_20240729_071435_968.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.june",
    year: 2024,
    pics: [
      [
        "/assets/images/ojp3/diary/2024-06/20240701_105124.jpg",
        "/assets/images/ojp3/diary/2024-06/20240701_113641.jpg",
        "/assets/images/ojp3/diary/2024-06/20240701_113644.jpg",
        "/assets/images/ojp3/diary/2024-06/20240703_102723.jpg",
        "/assets/images/ojp3/diary/2024-06/20240704_061027.jpg",
        "/assets/images/ojp3/diary/2024-06/20240704_104115.jpg",
        "/assets/images/ojp3/diary/2024-06/20240704_104127.jpg",
        "/assets/images/ojp3/diary/2024-06/20240704_104140.jpg",
        "/assets/images/ojp3/diary/2024-06/20240708_112355.jpg",
      ],
      [
        "/assets/images/ojp3/diary/2024-06/mini_20240701_105124.jpg",
        "/assets/images/ojp3/diary/2024-06/mini_20240701_113641.jpg",
        "/assets/images/ojp3/diary/2024-06/mini_20240701_113644.jpg",
        "/assets/images/ojp3/diary/2024-06/mini_20240703_102723.jpg",
        "/assets/images/ojp3/diary/2024-06/mini_20240704_061027.jpg",
        "/assets/images/ojp3/diary/2024-06/mini_20240704_104115.jpg",
        "/assets/images/ojp3/diary/2024-06/mini_20240704_104127.jpg",
        "/assets/images/ojp3/diary/2024-06/mini_20240704_104140.jpg",
        "/assets/images/ojp3/diary/2024-06/mini_20240708_112355.jpg",
      ],
    ],
    alts: [],
  },
]
